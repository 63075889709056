import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useMsal } from "@azure/msal-react";

import Aistars from "../assets/Aistars.svg";
import Expert from "../assets/Expert.svg";
import Learning from "../assets/Learning.svg";
import Program from "../assets/Program.svg";
import Events from "../assets/Events.svg";
import downArrowOrange from "../assets/downArrowOrange.svg";
import upArrowOrange from "../assets/upArrowOrange.svg";
import wadhwaniImage from "../assets/wadhwani.png";
import geneiOrange from "../assets/geneiLogoOrange.svg";
import profile_icon from "../assets/profile_icon.png";
import bell_icon from "../assets/bell_icon.png";
import HamburgerMenu from '../assets/Hamburger.svg';

const Navbar = () => {
  const url = window.location.pathname;
  const [openDropDown, setOpenDropDown] = useState(false);
  const buttonRef = useRef(null);
  const { instance, accounts } = useMsal();  // MSAL instance for handling login/logout
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication status on component mount
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsAuthenticated(!!token);
  }, [accounts]);

  const handleClick = (e) => {
    if (!openDropDown) {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = "/assistants";
    }
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
    setOpenDropDown(!openDropDown);
  };

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",  // Redirect to home page after logout
    }).then(() => {
      localStorage.removeItem("authToken");  // Clear the token from local storage
      setIsAuthenticated(false);  // Update authentication status
    }).catch(error => {
      console.error("Logout failed:", error);
    });
  };

  const handleLogin = () => {
    instance.loginPopup({
      scopes: [
        "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
        "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
      ],
    }).then(response => {
      const token = response.accessToken;
      localStorage.setItem("authToken", token);
      setIsAuthenticated(true);
    }).catch(error => {
      console.error("Logiuten failed:", error);
    });
  };

  return (
    <Box position={"sticky"} top={0} zIndex={1000}>
      <Flex
        bg="white"
        p={4}
        boxShadow="md"
        align="center"
        justify="space-between"
        display={{ base: "none", md: "flex" }}
      >
        <Box pl={5} bg="white">
          <Link href="/" textDecoration={"none"}>
            <Image
              src={wadhwaniImage}
              alt="Venture Box"
              style={{ height: "45px" }}
            />
          </Link>
        </Box>
        <HStack spacing={10} color={"#6F6F6F"}>
          <Link href="/" _hover={"unset"}>
            <Text color={url === "/" ? "#FF8452" : ""} fontWeight={"500"}>
              Home
            </Text>
          </Link>
          <Menu isLazy isOpen={openDropDown} onClose={() => setOpenDropDown(false)}>
            <Box>
              <MenuButton
                ref={buttonRef}
                as={Button}
                background="unset"
                _hover={{ bg: "unset" }}
                _expanded={{ bg: "unset" }}
                _focus={{ bg: "unset" }}
                fontWeight={"500"}
                px={0}
                color={window.location.pathname === "/agent-container"  ? "#FF8452" : ""}
                onClick={handleClick}
                rightIcon={
                  <Box
                    onClick={handleIconClick}
                    transform={openDropDown ? 'rotate(180deg)' : 'rotate(0)'}
                    transition="transform 0.2s"
                  >
                    <ChevronDownIcon />
                  </Box>
                }
              >
                AI Assistants
              </MenuButton>
            </Box>
            <MenuList>
              <a href="/marketing" style={{ width: '-webkit-fill-available', textDecoration: 'none' }}>
                <MenuItem>
                  Marketing
                </MenuItem>
              </a>
              <a href="/agent-container" style={{ width: '-webkit-fill-available', textDecoration: 'none' }}>
                <MenuItem>
                  Sales
                </MenuItem>
              </a>
              <a href="/strategy" style={{ width: '-webkit-fill-available', textDecoration: 'none' }}>
                <MenuItem>
                  Strategy
                </MenuItem>
              </a>
              <a href="/hr" style={{ width: '-webkit-fill-available', textDecoration: 'none' }}>
                <MenuItem>
                  Human Resources
                </MenuItem>
              </a>
            </MenuList>
          </Menu>
          <Text color={"#6F6F6F"}>Expert Network</Text>
          <Text color={"#6F6F6F"}>Learning Library</Text>
          <Text color={"#6F6F6F"}>Programs</Text>
          <Text color={"#6F6F6F"}>Events</Text>
          <Image src={bell_icon} w="24px" h="24px" alt="bell_icon" />
          <Menu>
            <MenuButton>
              <Image src={profile_icon} w="24px" h="24px" alt="profile_icon" />
            </MenuButton>
            <MenuList>
              {isAuthenticated ? (
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              ) : (
                <MenuItem onClick={handleLogin}>Login</MenuItem>
              )}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      {/* Mobile view */}
      <Flex
        bg="white"
        p={4}
        boxShadow="md"
        align="center"
        justify="space-between"
        display={{ base: "flex", md: "none" }}
      >
        <Box>
          <Link href="/" textDecoration={"none"}>
            <Image
              src={wadhwaniImage}
              alt="Wadhwani Foundation"
              style={{ height: "38px" }}
            />
          </Link>
        </Box>
        <Box display="flex" flexDirection={"row"} gap={"15px"} alignItems={"center"}>
          <Image src={bell_icon} w="24px" h="24px" alt="bell_icon" />
          <Menu>
            <MenuButton>
              <Image src={profile_icon} w="24px" h="24px" alt="profile_icon" />
            </MenuButton>
            <MenuList>
              {isAuthenticated ? (
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              ) : (
                <MenuItem onClick={handleLogin}>Login</MenuItem>
              )}
            </MenuList>
          </Menu>
          <Image src={HamburgerMenu} w="24px" h="24px" alt="profile_icon" cursor={"pointer"} onClick={onOpen} />
        </Box>
      </Flex>

      {/* Mobile drawer */}
      <Drawer
        placement="bottom"
        size={"full"}
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius="12px"
          backgroundColor={"#FEF8F5"}
          width={"98vw"}
          height={"98vh"}
          margin={"auto"}
          pt={4}
          pb={8}
          px={0}
        >
          <DrawerCloseButton />
          <DrawerBody>
            <VStack spacing={0} py={"25px"} align="stretch">
              <Text
                fontWeight={400}
                px={"12px"}
                py={"4px"}
                fontSize={"16px"}
                display={"flex"}
                gap={"12px"}
                lineHeight={"24px"}
                color={"#F68623"}
                alignItems={"center"}
              >
                <img src={geneiOrange} style={{ boxShadow: "2xp" }} /> Genie AI
              </Text>
              <Accordion allowToggle py="8px" borderY={"1px solid #FCDCBB"}>
                <AccordionItem border="none">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton justifyContent="space-between" _hover={{ bg: "gray.100" }}>
                        <Box display="flex" gap="12px" alignItems="center">
                          <img src={Aistars} />
                          AI Assistants
                        </Box>
                        {isExpanded ? (
                          <img src={upArrowOrange} />
                        ) : (
                          <img src={downArrowOrange} />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <VStack align="stretch" display={"flex"} gap={"12px"} flexDirection={"column"}>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              Product Development
                            </Box>
                          </Link>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              Strategy
                            </Box>
                          </Link>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              Marketing
                            </Box>
                          </Link>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              Sales
                            </Box>
                          </Link>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              Finance
                            </Box>
                          </Link>
                          <Link href="#" _hover={{ textDecoration: "none" }}>
                            <Box p={2} _hover={{ bg: "gray.100" }}>
                              HR
                            </Box>
                          </Link>
                        </VStack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                gap="12px"
                py={"26px"}
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"24px"}
                borderBottom={"1px solid #FCDCBB"}
              >
                <img src={Expert} /> Expert Network
              </Button>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                py={"26px"}
                gap="12px"
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"24px"}
                borderBottom={"1px solid #FCDCBB"}
              >
                <img src={Learning} /> Learning Library
              </Button>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                py={"26px"}
                gap="12px"
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"24px"}
                borderBottom={"1px solid #FCDCBB"}
              >
                {" "}
                <img src={Program} /> Programs
              </Button>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                py={"26px"}
                gap="12px"
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"24px"}
                borderBottom={"1px solid #FCDCBB"}
              >
                <img src={Events} /> Events
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
