import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  Collapse,
  Link,
} from "@chakra-ui/react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";

import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/genie_logo_full.png";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import home_icon from "../../assets/home_icon.png";
import start_new from "../../assets/start_new.png";
import useApiClient from "../../services/useApiClient";
import { shortenText } from "../../services/common";
import userProfile from "../../assets/You.png";

const SidebarItem = ({ label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
      <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const MarketingComponent = ({
  breadcrumbPaths,
  chatHistoryAPI,
  selectedChatIdAPI,
  newChat,
  sendMessagee,
  title
}) => {
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${chatHistoryAPI}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        scrollToBottom();
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };
  useEffect(() => {
    fetchChatHistory();
  }, [chatId]);
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    setChatId(chatId);
    // console.log(chatId);
    navigate(`${selectedChatIdAPI}/${chatId}`);
    scrollToBottom();
    // Here you might want to fetch the messages for the selected chat
    // and update the chatMessages state
  };

  const getNewChatId = async (question) => {
    try {
      const response = await api.post(`${newChat}`, {
        question,
      });
      if (response) {
        const data = response.data;
        console.log("New chat ID:", data.chat_id);
        setChatId(data.chat_id);
        console.log("Chat ID set:", chatId);
        return data.chat_id;
      } else {
        console.error("Error getting new chat ID:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error getting new chat ID:", error);
      return null;
    }
  };

  const sendMessage = async (message, chatId) => {
    try {
      const response = await api.post(`${sendMessagee}`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        return data;
      } else {
        console.error("Error sending message:", response.statusText);
        return { error: "Failed to send message" };
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return { error: "Failed to send message" };
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      // Add user message to chat
      setChatMessages((prev) => [
        ...prev,
        { type: "user", content: inputValue },
      ]);
      const userMessage = inputValue;
      setInputValue("");

      // Set loading state
      setIsLoading(true);

      let currentChatId = chatId;

      try {
        if (!currentChatId) {
          currentChatId = await getNewChatId(userMessage);
          setChatId(currentChatId);
          console.log("New chat ID:", currentChatId);
        }

        // Send message and get response
        const response = await sendMessage(userMessage, currentChatId);
        console.log("Response from sendMessage:", response);

        let aiResponse = "No response received";
        if (response && response.response) {
          aiResponse = response.response;
        } else if (response && response.error) {
          aiResponse = `Error: ${response.error}`;
        }

        setChatMessages((prev) => [
          ...prev,
          { type: "ai", content: aiResponse },
        ]);
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setChatMessages((prev) => [
          ...prev,
          {
            type: "ai",
            content: "Sorry, an error occurred while processing your message.",
          },
        ]);
      } finally {
        // Reset loading state
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex minH="100vh" bg="white">
      {/* Sidebar */}
      {/* <VStack
        align="start"
        w={{ base: "full", md: "280px" }}
        p={4}
        spacing={0}
        boxShadow="md"
        display={{ base: "none", lg: "block" }}
        bg="#FEF8F5"
      >
        <Link href="/" textDecoration={"none"} mb={2}>
          <Image src={home_icon} alt="Home" />
        </Link>
        <Box w="-webkit-fill-available" border={"1px solid #FEE6D4"}></Box>
        <HStack
          cursor="pointer"
          mt={"1em"}
          borderRadius={"8px"}
          bg={"#FEF1E9"}
          w="-webkit-fill-available"
          justifyContent={"center"}
          onClick={() => {
            setChatId(null);
            setChatMessages([]);
          }}
        >
          <Text bg={"#FEF1E9"} color={"#C01F27"} my={3}>
            Start New
          </Text>
          <Image src={start_new} alt="Start New" boxSize="20px" />
        </HStack>

        <SidebarItem
          label="Chat History"
          isOpen={openIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          {chatHistory && chatHistory.length > 0 ? (
            <Flex
              direction="column"
              bg="#FEF8F5"
              w="-webkit-fill-available"
              justifyContent="flex-start"
              height={"400px"}
              overflowY={"scroll"}
              className="hideScrollbar"
            >
              {chatHistory.map((chat, index) => (
                <Box
                  key={index}
                  pl={4}
                  py={2}
                  cursor={"pointer"}
                  onClick={() => handleSelectChat(chat?.chat_id)}
                >
                  <Text fontSize="md" fontWeight="medium" color="black">
                    {shortenText(chat?.question, 20) || `Chat ${index + 1}`}
                  </Text>
                </Box>
              ))}
            </Flex>
          ) : (
            <Box>No data available</Box>
          )}
        </SidebarItem>
      </VStack> */}
      <Flex
        gap={"12px"}
        height={{ base: "", sm: "100vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 5, sm: 16 }}
      >
        <VStack align="stretch" spacing={4}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            Marketing {title}
          </Text>
        </VStack>

        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
        //   mb={4}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
          px={4} // Add horizontal padding
        >
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              // bg={message.type === "user" ? "blue.100" : "gray.100"}
              p={2}
              className="hideScrollbar"
              borderRadius="md"
              mb={2}
              maxWidth="70%"
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"36px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
        </Flex>

        <Box
          position="sticky"
          bottom={0}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          mx={"auto"}
          bg="white"
          p={4}
        >
          <Flex
            border={"1px solid #D9D9D9"}
            borderRadius={"16px"}
            h={"70px"}
            align={"center"}
            justifyContent={"center"}
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border={"none"}
              h={"40px"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor={"transparent"}
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
            {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MarketingComponent;
