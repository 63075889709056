import React, { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  IconButton,
  useToast,
  Slide,
  Button,
  HStack,
} from "@chakra-ui/react";
import { Icon, createIcon, StarIcon } from "@chakra-ui/icons";
import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/genie_logo_full.png";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import useApiClient from "../../services/useApiClient";
import userProfile from "../../assets/You.png";

// Custom ThumbUpIcon
const ThumbUpIcon = createIcon({
  displayName: "ThumbUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"
    />
  ),
});

// Custom ThumbDownIcon
const ThumbDownIcon = createIcon({
  displayName: "ThumbDownIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4H6.56c-.71 0-1.37.37-1.73.97L2.17 11.12z"
    />
  ),
});

const PlaybookDefault = ({
  breadcrumbPaths,
  fetchHistory,
  selectChat,
  newChatId,
  sendMessagee,
}) => {
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [showRating, setShowRating] = useState(false);
  const [rating, setRating] = useState(0);
  const toast = useToast();

  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${fetchHistory}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, [chatId]);
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getNewChatId = async (question) => {
    try {
      const response = await api.post(`${newChatId}/new_chat`, {
        question,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        console.log("New chat ID:", data.chat_id);
        setChatId(data.chat_id);
        console.log("Chat ID set:", chatId);
        return data;
      } else {
        console.error("Error getting new chat ID:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error getting new chat ID:", error);
      return null;
    }
  };

  const handleImpression = async (messageId, impression) => {
    try {
      const response = await api.post("/sales/playbook/impression", {
        chat_id: chatId,
        message_id: messageId,
        impression,
      });

      if (response.status === 200) {
        setChatMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageid === messageId ? { ...msg, impression } : msg
          )
        );
        toast({
          title: "Feedback submitted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to submit feedback");
      }
    } catch (error) {
      console.error("Error submitting impression:", error);
      toast({
        title: "Failed to submit feedback",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const sendMessage = async (message, chatId) => {
    try {
      const response = await api.post(`${sendMessagee}/chat`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        return data;
      } else {
        console.error("Error sending message:", response.statusText);
        return { error: "Failed to send message" };
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return { error: "Failed to send message" };
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      setIsLoading(true);
      const userMessage = inputValue;
      setInputValue("");

      try {
        let currentChatId = chatId;
        if (!currentChatId) {
          const newChatResponse = await getNewChatId(userMessage);
          currentChatId = newChatResponse.chat_id;
          setChatId(currentChatId);
        }

        const response = await sendMessage(userMessage, currentChatId);

        if (response && !response.error) {
          setChatMessages((prev) => [
            ...prev,
            {
              type: "user",
              messageid: response.userMessageId,
              content: userMessage,
            },
            {
              type: "ai",
              messageid: response.aiMessageId,
              content: response.response,
            },
          ]);
          console.log("Chat messages:", chatMessages);

          // Check if it's time to show the rating popup
          if ((chatMessages.length + 2) % 10 === 0) {
            setShowRating(true);
          }
          console.log("Chat messages length:", chatMessages.length);
          console.log("Show rating:", showRating);
          console.log(
            "show the rating popup :",
            (chatMessages.length + 2) % 10
          );
        } else {
          throw new Error(response.error || "Failed to send message");
        }
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        toast({
          title: "Error",
          description: "Failed to send message. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRating = async (selectedRating) => {
    setRating(selectedRating);
    try {
      const response = await api.post("/sales/playbook/conversation-rating", {
        chat_id: chatId,
        conversation_rating: selectedRating,
      });

      if (response.status === 200) {
        toast({
          title: "Thank you for providing a rating!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to submit rating");
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast({
        title: "Failed to submit rating",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setRating(0);
      setShowRating(false);
    }
    
  };

  return (
    <Flex minH="100vh" bg="white">
      <Flex
        gap={"12px"}
        height={{ base: "", sm: "100vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 5, sm: 16 }}
      >
        <VStack align="stretch" spacing={4}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            Create a playbook plan
          </Text>
        </VStack>

        <Slide
          direction="bottom"
          in={showRating}
          style={{ zIndex: 10 }}
          align="center"
          justifyContent="center"
        >
          <Box p={4} bg="white" shadow="md" borderTopRadius="md">
            <Text mb={2}>How would you rate this conversation?</Text>
            <Flex alignContent={"center"} justifyContent={"center"}>
              <HStack spacing={2} align={"center"}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconButton
                    key={star}
                    icon={
                      <StarIcon
                        color={star <= rating ? "orange.400" : "gray.300"}
                      />
                    }
                    onClick={() => handleRating(star)}
                    aria-label={`Rate ${star} stars`}
                    variant="ghost"
                  />
                ))}
              </HStack>
            </Flex>
          </Box>
        </Slide>
        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
          mb={4}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
          px={4}
        >
          {chatMessages.map((message) => (
            <Box
              key={message.messageid}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              p={2}
              className="hideScrollbar"
              borderRadius="md"
              mb={2}
              maxWidth={{ base: "80%", lg: "90%" }}
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Venture Box AI
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"34px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
              {message.type === "ai" && (
                <Flex justifyContent="flex-end" mt={2}>
                  <IconButton
                    icon={<ThumbUpIcon />}
                    aria-label="Thumbs up"
                    size="sm"
                    mr={2}
                    onClick={() => handleImpression(message.messageid, true)}
                    colorScheme={message.impression === true ? "green" : "gray"}
                  />
                  <IconButton
                    icon={<ThumbDownIcon />}
                    aria-label="Thumbs down"
                    size="sm"
                    onClick={() => handleImpression(message.messageid, false)}
                    colorScheme={message.impression === false ? "red" : "gray"}
                  />
                </Flex>
              )}
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
        </Flex>

        <Box
          position="sticky"
          bottom={0}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          bg="white"
          mx={"auto"}
          p={4}
        >
          <Flex
            border={"1px solid #D9D9D9"}
            borderRadius={"16px"}
            h={"70px"}
            align={"center"}
            justifyContent={"center"}
          >
            <Input
              placeholder="How can Venture Box help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border={"none"}
              h={"40px"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor={"transparent"}
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PlaybookDefault;
