import React from "react";
import MarketingComponent from "./MarketingComponent";

const AdCopy = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Marketing", href: "/marketing" },
    { label: "Marketing Adcopy Assistant", href: `/marketing/adcopy-assistant` },
  ];
  return (
    <div>
      <MarketingComponent
        breadcrumbPaths={breadcrumbPaths}
        chatHistoryAPI={"/marketing/adcopy_assistant/list"}
        selectedChatId={"/marketing/adcopy-assistant"}
        newChat={"/marketing/adcopy_assistant/new_chat"}
        sendMessagee={"/marketing/adcopy_assistant/chat"}
        title={"Adcopy Assistant"}
      />
    </div>
  );
};


export default AdCopy;
