import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Link,
  Avatar,
  Input,
  Spinner,
  Divider,
  AbsoluteCenter,
  Img,
  SimpleGrid,
  Stack,
  Tag,
} from "@chakra-ui/react";

import sales_icon from "../../assets/sales_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import send_active_icon from "../../assets/send_active_icon.png";
import attach from "../../assets/attach.svg";
import Brainstorm from "../../assets/Brainstorm.svg";
import conversion from "../../assets/conversion.svg";
import Gtm from "../../assets/GTM.svg";
import newcustomers from "../../assets/newcustomers.svg";
import Outreach from "../../assets/Outreach.svg";
import salesPitch from "../../assets/salesPitch.svg";
import salesTraining from "../../assets/salestraining.svg";
import Sentiment from "../../assets/Sentiment.svg";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../services/useApiClient";
import Footer from "../Footer/Footer";

const tasks = [
  {
    name: "Playbook",
    description:
      "Craft compelling message to persuade customers and close deals.",
    rating: "4.7",
    conversations: "29K+",
    creator: "Venture Box AI",
    image: salesPitch,
    route: "/sales/playbook",
  },
  // {
  //   name: "Create GTM Plan",
  //   description: "Develop sales-focused strategy for product launch success.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: Gtm,
  //   route: "",
  // },
  {
    name: "Create a Sales Script",
    description:
      "Craft compelling message to persuade customers and close deals.",
    rating: "4.7",
    conversations: "29K+",
    creator: "Venture Box AI",
    image: salesPitch,
    route: "/sales/sales-draft",
  },
  {
    name: "Sales Advisor",
    description:
      "An experienced Sales Advisor with a deep understanding of high-level sales strategies and tactics",
    rating: "4.7",
    conversations: "29K+",
    creator: "Venture Box AI",
    image: Sentiment,
    route: "/sales/sales-advisor",
  },
  {
    name: "Sales Assistant",
    description:
      "A proactive Sales Assistant specializing in supporting routine sales tasks and improving operational efficiency.",
    rating: "4.7",
    conversations: "29K+",
    creator: "Venture Box AI",
    image: Sentiment,
    route: "/sales/sales-assistant",
  },
  {
    name: "Sales consultant",
    description:
      "A versatile Sales Consultant capable of addressing a wide range of sales-related queries",
    rating: "4.7",
    conversations: "29K+",
    creator: "Venture Box AI",
    image: Sentiment,
    route: "/sales/sales-consultant",
  },
  // {
  //   name: "Sales Conversation Simulator ",
  //   description:
  //     "AI Assistant for practicing and improving skills in sales dialogues through simulation.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: conversion,
  //   route: "",
  // },
  // {
  //   name: "Design Sales Training ",
  //   description:
  //     "Develop training programs to enhance sales skills efficiently.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: salesTraining,
  //   route: "",
  // },

  // {
  //   name: "Brainstorm Sales Strategies",
  //   description:
  //     "Identify innovative sales strategies to boost sales performance.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: Brainstorm,
  //   route: "",
  // },
  // {
  //   name: "Find new Customer Segments",
  //   description: "Identify untapped markets for sales expansion opportunities.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: newcustomers,
  //   route: "",
  // },
  // {
  //   name: "Sales Email & Outreach Generator",
  //   description: "Create automated personalized emails for business outreach",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: Outreach,
  //   route: "",
  // },
  // {
  //   name: "Customer Sentiment Analyzer",
  //   description:
  //     "Craft a persuasive dialogue to guide customer conversations effectively.",
  //   rating: "4.7",
  //   conversations: "29K+",
  //   creator: "Venture Box AI",
  //   image: Sentiment,
  //   route: "",
  // },
];

const AgentContainer = () => {
  const [inputValue, setInputValue] = useState("");
  const { api, conversation_api } = useApiClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sendMessage = async (messageToSend, newChatId) => {
    const message = messageToSend || inputValue;
    const currentChatId = newChatId;
    setInputValue("");

    try {
      await api.post(`/sales_coach/chat`, {
        message,
        chat_id: currentChatId,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleConversation = async (value) => {
    setLoading(true);
    if (value || inputValue.trim()) {
      try {
        const response = await api.post(`/sales_coach/new_chat`, {
          question: value || inputValue,
        });
        const data = response.data;
        console.log("New chat data:", data);
        await sendMessage(value || inputValue, data.chat_id);
        setLoading(false);
        navigate(`/sales/sales-coach/${data.chat_id}`);
      } catch (error) {
        setLoading(false);
        console.error("Error starting new chat:", error);
      }
    }
  };

  return (
    <Flex direction="column" h="100vh" bg="#f7f8fa">
      <Flex flex="1">
        {loading && (
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="9999"
          >
            <Spinner
              size="xl"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.100"
              color="#FF8452"
            />
          </Box>
        )}
        <Box flex="1" p={4} bg={"white"}>
          {/* <Image src={sales_coach} alt="sales_agent" mb={5} /> */}
          <Flex
            justifyContent={"center"}
            direction={"column"}
            p={4}
            borderRadius="md"
            alignItems="center"
            width="100%"
            maxWidth={{ base: "100%", lg: "82rem" }}
            mx={"auto"}
          >
            <Flex
              justifyContent={"center"}
              direction={"row"}
              p={4}
              borderRadius="md"
              alignItems="center"
              width="100%"
            >
              <Avatar src={sales_icon} mr={4} />
              <Box>
                <Text color="orange.500" fontWeight="bold" fontSize="lg">
                  Sales Coach
                </Text>
                <Text fontSize="md">How can I help you Today?</Text>
              </Box>
            </Flex>
            <Box
              // mb={8}
              pos={"relative"}
              width={{ base: "100%", sm: "75%", lg: "65%" }}
            >
              <Flex
                border={"1px solid #D9D9D9"}
                borderRadius={"16px"}
                h={"70px"}
                align={"center"}
                justifyContent={"center"}
                _focusWithin={{
                  border: "2px solid #E5A6A8",
                  transition: "all",
                  transitionDuration: "0.5s",
                  boxShadow: "1px 0px 4px 0px #E5A6A8",
                }}

                // _focusWithin={{border:"2px solid #E5A6A8",transition:"all",transitionDuration:"0.5s",boxShadow:"1px 0px 4px 0px #E5A6A8"}}
              >
                <Input
                  placeholder="How can Venture Box help you today?"
                  size={{ base: "sm", sm: "sm" }}
                  bg="white"
                  pr="4.5rem"
                  border={"none"}
                  h={"40px"}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleConversation()}
                  focusBorderColor="transparent"
                  _focus={{ boxShadow: "none" }}
                />
                <Image
                  w={{ base: "30px", lg: "30px" }}
                  mr={3}
                  src={inputValue ? send_active_icon : send_inactive_icon}
                  alt="send_icon"
                  onClick={() => handleConversation()}
                />
                {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
              </Flex>
            </Box>
            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={{ base: 4, sm: 4 }}
              mb={8}
              px={{ base: "20px", sm: "0px" }}
              justifyContent={"center"}
              // width={"100vw"}
              py={4}
              mx={"auto"}
              className="no-scrollbar"
              align={{ base: "stretch", sm: "center" }}
            >
              <Text size="lg" color={"#666666"} fontWeight={400}>
                Get started with a question
              </Text>
              <Flex
                direction={"row"}
                className="hideScrollbar"
                spacing={4}
                overflowX="auto"
                gap={"12px"}
                maxWidth={{ base: "100%", lg: "535px" }}
              >
                <Tag
                  fontSize={{ base: "10px", lg: "14px" }}
                  cursor={"pointer"}
                  // width={"100%"}
                  flexShrink="0"
                  borderRadius={"3px"}
                  background={"#FFF3E9"}
                  color={"#F68721"}
                  fontWeight={400}
                  onClick={() => handleConversation("What is a Sales Pitch?")}
                >
                  What is a Sales Pitch?
                </Tag>
                <Tag
                  fontSize={{ base: "10px", lg: "14px" }}
                  // width={"100%"}
                  cursor={"pointer"}
                  borderRadius={"3px"}
                  background={"#FFF3E9"}
                  color={"#F68721"}
                  fontWeight={400}
                  padding={"5px 10px"}
                  flexShrink="0"
                  onClick={() =>
                    handleConversation(
                      "How can sales teams avoid making assumptions about customer needs?"
                    )
                  }
                >
                  How can sales teams avoid making assumptions about customer
                  needs?
                </Tag>
              </Flex>
            </Stack>
          </Flex>

          <Box
            position="relative"
            padding="10"
            marginTop={"30px"}
            justifyContent={{ base: "flex-start", sm: "center" }}
          >
            <Divider
              width={"100%"}
              display={{ base: "none", sm: "block" }}
              border={"1px solid #FCDCBB"}
              color={"#FCDCBB"}
            />
            <Link href="/business-plan">
              <AbsoluteCenter
                bg={"white"}
                px="4"
                fontSize={{ base: "20px", sm: "30px" }}
                left={{ base: "30%", sm: "50%" }}
                alignItems={"left"}
              >
                Sales Task Assistants
              </AbsoluteCenter>
            </Link>
          </Box>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing="35px"
            width="100%"
            mx={"auto"}
            maxWidth={{ base: "100%", lg: "82rem" }}
            justifyContent="center"
          >
            {tasks.map((task, idx) => (
              <Flex
                key={idx}
                maxW={{ base: "100%", sm: "468px" }}
                minW="360px"
                bg="#FEF8F5"
                gap="20px"
                pX={8}
                maxH="260px"
                minH="240px"
                justifyContent="flex-start"
                alignContent="flex-start"
                borderRadius="12px"
                alignItems="center"
              >
                <Flex justifyContent="center" alignItems="center" m={5}>
                  <Img h="80px" w="80px" src={task.image} alt="" />
                </Flex>
                <Box bg="#FEF8F5" py="16px" borderRadius="md" mb={0}>
                  <Flex direction="column" gap="8px">
                    <Heading as="h3" size="sm" fontWeight={500} fontSize="16px">
                      {task.name}
                    </Heading>
                    <Text fontSize="12px" color="#666666">
                      {task.description}
                    </Text>
                    <Flex align="center" gap="8px">
                      <Text fontSize="12px" color="#333333">
                        <span style={{ color: "#F2682B", fontWeight: "bold" }}>
                          {task.rating}
                        </span>{" "}
                        (1k Ratings)
                      </Text>
                      <span style={{ color: "#999999" }}>|</span>
                      <Text fontSize="12px" color="#333333">
                        <span style={{ color: "#F2682B", fontWeight: "bold" }}>
                          {task.conversations}
                        </span>{" "}
                        Conversations
                      </Text>
                    </Flex>
                  </Flex>
                  <Text
                    borderBottom="1px solid #FCDCBB"
                    display="flex"
                    gap="5px"
                    py={1}
                    fontSize="12px"
                    color="#666666"
                  >
                    Created by: <Text color="#000">{task.creator}</Text>
                  </Text>
                  <Flex alignItems="center" direction="row" gap="16px" mt={4}>
                    <Link href={task.route}>
                      <Button
                        colorScheme="red"
                        paddingX="16px"
                        paddingY="10px"
                        fontWeight={400}
                      >
                        Start now
                      </Button>
                    </Link>
                    <Text ml={3} color="#C01F27" fontWeight={400}>
                      Watch Demo
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
      <Footer/>
    </Flex>
  );
};

export default AgentContainer;
