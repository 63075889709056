import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BusinessPlanPage from './pages/BusinessPlanPage.js';
import SignupPage from './pages/SignupPage.js';
import LoginPage from './pages/LoginPage.js';
import MainLayout from './pages/MainLayout.js';
import PlanPage from './components/PlanPage.js';
import HomePage from './components/HomePage.js';
import AgentContainer from './components/AgentContainer/AgentsContainer.js';
import Conversation from './components/Conversation.js';
import EmailDraft from './components/Email.js';
import SalesDraft from './components/SalesDraft.js';
import Signup from './pages/Signup.js';
import SignIn from './pages/SignIn.js';
import Assistants from './pages/Assistants.js';
import Finance from './components/Finance/Finance.js';
import Hr from './components/HR/Hr.js';
import Sales from './components/Sales/Sales.js';
import Strategy from './components/Strategy/Strategy.js';
import ProductDev from './pages/ProductDev.js';
import Gtm from './pages/Gtm.js';
import GtmA from './components/AgentContainer/Gtm.js';
import GtmS from './components/Strategy/Gtm.js';
import GtmM from './components/Marketing/Gtm.js';
import GtmSales from './components/Sales/Gtm.js';
import GtmH from './components/HR/Gtm.js';
import GtmF from './components/Finance/Gtm.js';
import Marketing from './components/Marketing/Marketing.js';
import SalesCoach from './components/AgentContainer/SalesCoach.js';
import ChatPage from './components/Marketing/ChatPage.js';
import ChatPageH from './components/HR/Chatpage.js';
import ChatPageS from './components/AgentContainer/ChatPage.js';
import ChatPageSales from './components/Sales/ChatPage.js';
import ChatPageF from './components/Finance/ChatPage.js';
import ProtectedRoute from './ProtectedRoute.js'; // Import the ProtectedRoute
import MarketingCoach from './components/Marketing/MarketingCoach.js';
import HrCoach from './components/HR/HrCoach.js';
import StrategyCoach from './components/Strategy/StrategyCoach.js';
import IdeaGenerator from './components/Strategy/IdeaGenerator.js';
import IdeaChallenger from './components/Strategy/IdeaChallenger.js';
import IdeaChallengerChatPage from './components/Strategy/IdeaChallengerChatPage.js';
import IdeaGeneratorChatPage from './components/Strategy/ideaGeneratorChatPage.js';
import IdeaEnhancer from './components/Strategy/ideaEnhancer.js';
import IdeaEnhancerChatPage from './components/Strategy/IdeaEnhancerChatPage.js';
import ChatpageStrategy from './components/Strategy/ChatPafeStrategy.js';
import ChatPageMark from './components/Marketing/ChatPage.js';
import SalesAdvisor from './components/Sales/SalesAdvisor.js';
import SalesAdvisorChat from './components/Sales/SaleAdvisorChat.js';
import SalesAssistant from './components/Sales/SalesAssistant.js';
import SalesAssistantChat from './components/Sales/SaleAssistantChat.js';
import SalesConsultant from './components/Sales/SalesConsultant.js';
import SalesConsultantChat from './components/Sales/SalesConsultantChat.js';
import ContentCalendar from './components/Marketing/ContentCalendar.js';
import ContentCalendarChatPage from './components/Marketing/ContentCalendarChatPage.js';
import UserPersonaCreator from './components/Marketing/UserPersona.js';
import UserPersonaChat from './components/Marketing/UserPersonChat.js';
import AdCopyChat from './components/Marketing/AdCopyChat.js';
import AdCopy from './components/Marketing/AdCopy.js'
import Privacy from './components/PrivacyPolicy/Privacy.jsx';
import Terms from './components/Terms/Terms.jsx';
const RoutesConfig = () => (
  <Routes>
    <Route path="/home" element={<MainLayout />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/agent-container" element={<AgentContainer />} />
    <Route path="/venture-box/master-coach" element={<Conversation />} />
    <Route path="/venture-box/master-coach/:chatId" element={<Conversation />} />
    <Route path="/sales/sales-coach" element={<Conversation />} />
    <Route path="/sales/sales-coach/:chatId" element={<Conversation />} />
    <Route path="/hr/hr-coach" element={<Conversation/>} />
    <Route path="/hr/hr-coach/:chatId" element={<Conversation/>} />
    <Route path="/email-draft" element={<EmailDraft />} />
    <Route path="/sales/sales-draft" element={<SalesDraft />} />
    <Route path="/finance" element={<Finance />} />
    <Route path="/finance/playbook" element={<GtmF />} />
    <Route path="/hr" element={<Hr />} />
    <Route path="/venture-box/master-coach/playbook/:chatId" element={<ChatPageS />} />
    <Route path="/strategy/strategy-coach" element={<Conversation/>} />
    <Route path="/strategy/strategy-coach/:chatId" element={<Conversation/>} />
    <Route path="/marketing/marketing-coach" element={<Conversation/>} />
    <Route path="/marketing/marketing-coach/:chatId" element={<Conversation/>} />
    <Route path="/hr/playbook" element={<GtmH />} />
    <Route path="/hr/playbook/:chatId" element={<ChatPageH />} />
    <Route path="/sales" element={<Sales />} />
    <Route path="/sales/playbook" element={<GtmSales />} />
    <Route path="/sales/playbook/:chatId" element={<ChatPageSales />} />
    <Route path="/finance/playbook/:chatId" element={<ChatPageF />} />
    <Route path="/strategy" element={<Strategy />} />
    <Route path="/strategy/playbook" element={<GtmS />} />
    <Route path="/strategy/playbook/:chatId" element={<ChatpageStrategy />} />
    <Route path="/strategy/idea-challenger" element={<IdeaChallenger />} />
    <Route path="/strategy/idea-challenger/:chatId" element={<IdeaChallengerChatPage />} />
    <Route path="/strategy/idea-generator" element={<IdeaGenerator />} />
    <Route path="/strategy/idea-generator/:chatId" element={<IdeaGeneratorChatPage />} />
    <Route path="/marketing/marketing-social-media-content-calendar" element={<ContentCalendar />} />
    <Route path="/marketing/marketing-social-media-content-calendar/:chatId" element={<ContentCalendarChatPage />} />
    <Route path="/marketing/marketing-persona-creator/" element={<UserPersonaCreator/>} />
    <Route path="/marketing/marketing-persona-creator/:chatId" element={<UserPersonaChat />} />
  
    <Route path="/privacy-policy" element={<Privacy/>} />
    <Route path="/terms" element={<Terms/>} />


    <Route path="/marketing/adcopy-assistant" element={<AdCopy/>} />
    <Route path="/marketing/adcopy-assistant/:chat_id" element={<AdCopyChat />} />
    
    <Route path="/sales/sales-advisor" element={<SalesAdvisor />} />
    <Route path="/sales/sales-advisor/:chatId" element={<SalesAdvisorChat />} />
    <Route path="/sales/sales-assistant" element={<SalesAssistant />} />
    <Route path="/sales/sales-assistant/:chatId" element={<SalesAssistantChat />} />
    <Route path="/sales/sales-consultant" element={<SalesConsultant />} />
    <Route path="/sales/sales-consultant/:chatId" element={<SalesConsultantChat />} />
    <Route path="/strategy/idea-enhancer" element={<IdeaEnhancer />} />
    <Route path="/strategy/idea-enhancer/:chatId" element={<IdeaEnhancerChatPage />} />
    <Route path="/marketing" element={<Marketing />} />
    <Route path="/marketing/playbook" element={<GtmM />} />
    <Route path="/marketing/playbook/:chatId" element={<ChatPageMark/>} />
    <Route path="/product-dev" element={<ProductDev />} />
    <Route path="/gtm" element={<Gtm />} />
    <Route path="/business-plan/:section" element={<BusinessPlanPage />} />
    <Route path="/general-conversation" element={<Conversation />} />
    <Route path="/assistants" element={<Assistants />} />
    <Route
      path="/business-plan"
      element={<ProtectedRoute element={<BusinessPlanPage />} />}
    />
  </Routes>
);

export default RoutesConfig;
