import React, { useEffect, useRef, useState, memo } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Icon,
  VStack,
  HStack,
  Tag,
  Avatar,
  Image,
  Link,
  Grid,
  GridItem,
  Spinner,
  Stack,
  SimpleGrid,
  Divider,
  AbsoluteCenter,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
// import customerRetention from "../assets/customerRetention.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import announce from "../assets/announce.svg";
import growthMoney from "../assets/growthMoney.svg";
import bulb from "../assets/bulb.svg";
import customerRetention from "../assets/customerRetention.svg";
import internet from "../assets/internet.png";
import handShaking from "../assets/handShaking.svg";
import parliament from "../assets/parliament.svg";

import wallet from "../assets/wallet.svg";

import genie_logo_full from "../assets/genie_logo_full.png";

import send_inactive_icon from "../assets/search_button.png";
import send_active_icon from "../assets/send_active_icon.png";
import hr_icon from "../assets/AI Team/4.png";
import marketing_icon from "../assets/AI Team/1.png";
import sales_icon from "../assets/AI Team/2.png";
import strategy_icon from "../assets/AI Team/3.png";
// import { CONVERSATION_API_URL } from "../services/api";
import AnnouncementMarquee from "./Marquee/AnnouncementMarquee";
import useApiClient from "../services/useApiClient";
import { createProjectAsync, setProjectName as setProjectNameRedux, setSectionName } from '../actions/projectActions';
import Footer from "./Footer/Footer";

const sections = [
  {
    // title: "Task agents for entrepreneurs",
    items: [
      {
        name: "Marketing",
        image: marketing_icon,
        description:
          "Tired of figuring out marketing on your own? Work together with our marketing coach to take your marketing to next level.",
        rating: "4.7",
        conversations: "21,000+",
        to: "/marketing",
      },
      {
        name: "Sales",
        image: sales_icon,
        description:
          "Increase sales Pipeline and revenue with the help of AI sales coach by automating lead generation and sales process.",
        rating: "4.7",
        conversations: "29K",
        to: "/agent-container",
      },
      {
        name: "Strategy",
        image: strategy_icon,
        description:
          "Use the expertise of our finance coach in various aspects of  finance for startups and founders",
        rating: "4.7",
        to: "/strategy",
        conversations: "29K+",
      },
      {
        name: "HR",
        image: hr_icon,
        description:
          "Overcome common people-management challenges with the help of human resources coach.",
        rating: "4.7",
        to: "/hr",
        conversations: "29K+",
      },
    ],
  },
];

const HomePage = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { api } = useApiClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [businessName, setBusinessName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);


  const sendMessage = async (messageToSend, newChatId) => {
    const message = messageToSend || inputValue;
    const currentChatId = newChatId;
    const body = JSON.stringify({ message, chat_id: currentChatId });
    setInputValue("");
    try {
      await api.post(`/master_coach/chat`, {
        message,
        chat_id: currentChatId,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleConversation = async (value) => {
    setLoading(true);
    const query = value || inputValue;
    if (value) {
      try {
        const response = await api.post(`/master_coach/new_chat`, {
          question: value || inputValue,
        });

        const data = response;
        const chatId = data.data.chat_id;
        // console.log(data.data.chat_id);

        const newChatAPI = await api.post(`/master_coach/chat`, {
          chat_id: chatId,
          message: query,
        });

        setLoading(false);
        // await newChatAPI;
        // if (newChatAPI.status === 200 || 201) {
        console.log(newChatAPI);
        navigate(`/venture-box/master-coach/${data.data.chat_id}`, { state: { query } });
        setLoading(false);
        // } else {ls
        
        //   setLoading(false);
        //   navigate(`/`);
        // }
        // console.log(data.data.chat_id)
        // sendMessage(value, data.data.chat_id);
      } catch (error) {
        console.error("Error starting new chat:", error);
      }
      // dispatch(setQuery(value)); // Dispatch the query to the Redux store
      // navigate("/general-conversation");
    } else if (inputValue.trim()) {
      try {
        const response = await api.post(`/master_coach/new_chat`, {
          question: value || inputValue,
        });

        const data = response;
        console.log(data);
        await sendMessage(inputValue, data.chat_id);
        // console.log(data.data.chat_id)
        // sendMessage(value, data.data.chat_id);
        navigate(`/venture-box/master-coach/${data.data.chat_id}`);
      } catch (error) {
        console.error("Error starting new chat:", error);
      } finally {
        setLoading(false);
      }
      // console.log(inputValue)
      // dispatch(setQuery(inputValue)); // Dispatch the query to the Redux store
      // navigate("/general-conversation"); // Navigate to the conversation page without state
    }
  };

  const handleBusinessPlanSubmit = async () => {
    if (!businessName.trim()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await api.post("/strategy/businessplan/create_project", {
        project_name: businessName,
      });

      if (response.status === 200) {
        dispatch(setProjectNameRedux(businessName));
        dispatch(setSectionName("company_description"));
        localStorage.setItem('projectName', businessName);
        localStorage.setItem('sectionName', "company_description");
      navigate(`/business-plan/company_description`);
      }
    } catch (error) {
      console.error("Error creating business plan:", error);
    } finally {
      setIsSubmitting(false);
      onClose(); // Close the modal
    }
  };

  const DesignCard = ({ icon, title, description, daysAgo, onClick }) => (
    <Box
      borderRadius="lg"
      p={4}
      bg="white"
      display={"flex"}
      gap={"8px"}
      backgroundColor={"#FBF8FC"}
      _hover={{
        textDecoration: "none",
        backgroundColor: "#F7F1FB",
        transition: "all 0.3s",
      }}
      cursor={onClick ? "pointer" : "default"}
      onClick={onClick}
    >
      <Flex gap={"12px"}>
        <Image
          src={icon}
          boxSize={12}
          width={"40px"}
          height={"40px"}
        />
        <VStack align={"start"} justifyContent={"space-between"} height={"100%"}>
          <VStack align="start" spacing={"8px"} mt={"6px"}>
            <Text fontWeight="medium" color={"#333333"} fontSize={"20px"}>
              {title}
            </Text>
            <Text fontSize="14px" color="gray.600">
              {description}
            </Text>
          </VStack>
          <Flex direction={"column"} width={"100%"}>
            <hr style={{ border: "1px solid #EFE3F6", width: "100%", marginTop: "10px" }} />
            <Text fontSize="12px" mt={"2px"} color="#F68623">
              {daysAgo} days ago
            </Text>
          </Flex>
        </VStack>
      </Flex>
    </Box>
  );

  const announcements = [
    "New government program launched to help agri tech start ups",
    "Tech conference scheduled for next month",
    "Local startup secures major funding",
  ];
  return (
    <>
    <Flex
      direction="column"
      h="100%"
      className="hideScrollbar"
      backgroundColor={"#fff"}
      overflowX={"hidden"}
    >
      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
        >
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.100"
            color="#FF8452"
          />
        </Box>
      )}
      <Flex bg="white" height={"100%"}>
        {/* <Flex position={"absolute"} top={"50%"} zIndex={"120"}>
          <SideNavigation />
        </Flex> */}
        {/* <Image src={Sidenav_homepage} alt="Sidenav_homepage" w={"61px"} /> */}
        <Box bg="#ffffff" p={8} pl={0}>
          <Flex
            align="center"
            justify="space-between"
            flexDirection={"column"}
            mb={8}
          >
            {/* <Image w={"60%"} src={home_head_content} alt="" /> */}
            <Flex
              direction={{ base: "column", md: "row" }}
              align={{ base: "center", md: "flex-end" }}
              justifyContent={{ base: "center", md: "flex-start" }}
            >
              <Image
                src={genie_logo_full}
                h={"120px"}
                display={{ base: "none", sm: "block" }}
                mr={2}
              />
              <Flex
                px={{ base: "20px", lg: "0px" }}
                flexDirection={"column"}
                ml={5}
              >
                <Heading
                  as="h1"
                  size="lg"
                  color={"#333333"}
                  pb={{ base: "4px", sm: "" }}
                  pt={{ base: "20px", sm: "" }}
                  fontSize={{ base: "24px", sm: "40px" }}
                  textAlign={{ base: "center", sm: "" }}
                  fontWeight={"500"}
                  // px={"20px"}
                >
                  Create a Successful Venture
                </Heading>
                <Text
                  mt={2}
                  textAlign={{ base: "center", sm: "left" }}
                  color={"#666666"}
                  fontSize={"14px"}
                >
                  Get the resources you need to succeed in your entrepreneurial
                  journey
                </Text>
                <Text
                  mt={2}
                  justifyContent={{ base: "center", sm: "start" }}
                  display={"flex"}
                  gap={"5px"}
                >
                  <Text color={"#F2682B"}>102680+</Text> Conversations Today
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {/* Search and below tabs */}
          <Flex
            align="center"
            justify="space-between"
            flexDirection={"column"}
            mb={2}
          >
            <Box
              mb={4}
              pos={"relative"}
              width={{ base: "80%", sm: "85%" }}
              maxWidth={{ base: "100%", lg: "60rem" }}
            >
              <Flex
                border={"1px solid #D9D9D9"}
                borderRadius={"16px"}
                h={"70px"}
                boxShadow={"sm"}
                align={"center"}
                width={"100%"}
                justifyContent={"center"}
                _focusWithin={{
                  border: "2px solid #E5A6A8",
                  transition: "all",
                  transitionDuration: "0.5s",
                  boxShadow: "1px 0px 4px 0px #E5A6A8",
                }}
              >
                <Input
                  placeholder="How can Venture Box help you today?"
                  size={{ base: "sm", sm: "sm" }}
                  backgroundColor={{ base: "transparent", sm: "white" }}
                  pr="4.5rem"
                  border={"none"}
                  h={{ base: "30px", lg: "40px" }}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleConversation(inputValue)
                  }
                  focusBorderColor="transparent"
                  _focus={{ boxShadow: "none" }}
                />

                <Image
                  w={{ base: "30px", lg: "40px" }}
                  mr={3}
                  src={inputValue ? send_active_icon : send_inactive_icon}
                  alt="send_icon"
                  onClick={() => handleConversation(inputValue)}
                />
                {/* <Icon
              as={SearchIcon}
              pos={"absolute"}
              right={3}
              top={3}
              onClick={() => handleConversation()}
            /> */}
              </Flex>
            </Box>
          
          {/* Announcement */}
            {/* <Box position="relative">
              <Flex
                position="absolute"
                alignItems="center"
                transform={{
                  base: "translateX(-180px)",
                  sm: "translateX(-250px)",
                }}
                mt={{ base: "100px", sm: "50px" }}
              >
                <Image src={announce} mr={2} />
                <Box width={{ base: "15%", sm: "20%" }}>
                  {" "}

                  <AnnouncementMarquee announcements={announcements} />
                </Box>
              </Flex>
            </Box> */}

            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={{ base: 4, sm: 4 }}
              mb={8}
              px={{ base: "20px", sm: "0px" }}
              justifyContent={"center"}
              width={"100vw"}
              className="no-scrollbar"
              align={{ base: "stretch", sm: "center" }}
            >
              <Text size="lg" color={"#666666"} fontWeight={400}>
                Get started with a question
              </Text>
              <Flex
                direction={"row"}
                className="hideScrollbar"
                spacing={4}
                overflowX="auto"
                gap={"12px"}
                maxWidth={{ base: "100%", lg: "500px" }}
              >
                <Tag
                  fontSize={{ base: "10px",sm:"12px", md:"12px", lg: "14px" }}
                  // width={"100%"}
                  cursor={"pointer"}
                  borderRadius={"3px"}
                  background={"#FFF3E9"}
                  color={"#F68721"}
                  fontWeight={400}
                  padding={"5px 10px"}
                  flexShrink="0"
                  onClick={() =>
                    handleConversation("How can I secure funding for my startup?")
                  }
                >
                  How can I secure funding for my startup?
                </Tag>
                <Tag
                  fontSize={{ base: "10px", sm:"12px", md:"12px",lg: "14px" }}
                  cursor={"pointer"}
                  // width={"100%"}
                  flexShrink="0"
                  borderRadius={"3px"}
                  background={"#FFF3E9"}
                  color={"#F68721"}
                  fontWeight={400}
                  onClick={() => handleConversation("How can I scale my business efficiently?")}
                >
                  How can I scale my business efficiently?
                </Tag>
                <Tag
                  fontSize={{ base: "10px", sm:"12px", md:"12px", lg: "14px" }}
                  cursor={"pointer"}
                  flexShrink="0"
                  borderRadius={"3px"}
                  background={"#FFF3E9"}
                  color={"#F68721"}
                  fontWeight={400}
                  onClick={() =>
                    handleConversation("How do I create a strong business strategy for long-term growth?")
                  }
                >
                  How do I create a strong business strategy for long-term growth?
                </Tag>
                
              </Flex>
            </Stack>
          </Flex>
          <Box mt={8}>
            <Box position="relative" padding="10" zIndex={0}>
              <Divider
                width={"100%"}
                display={{ base: "none", sm: "block" }}
                border={"1px solid #FCDCBB"}
                color={"#FCDCBB"}
              />
              <Link href="/business-plan">
                <AbsoluteCenter
                  bg={{ base: "transparent", sm: "white" }}
                  width={{ base: "100%", sm: "auto" }}
                  p="4"
                  fontSize={{ base: "20px", sm: "20px" }}
                  left={{ base: "50%", sm: "50%" }}
                  alignItems={"left"}
                >
                  Your Recent Interactions
                </AbsoluteCenter>
              </Link>
            </Box>
            <SimpleGrid
              columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
              maxW={"100%"}
              marginX={"40px"}
              gap={"23px"}
              height={"100%"}
              mx={"auto"}
              px={{ base: "20px", sm: "0px" }}
              maxWidth={{ base: "100%", lg: "82rem" }}
              textDecoration={"none"}
              spacing={4}
            >
              <DesignCard
                icon={bulb} // Replace with appropriate icon
                title="Design a Sales Pitch"
                description="Craft compelling message to persuade customers and close deals."
                daysAgo={6}
              />
              {/* <DesignCard
                icon={customerRetention}
                title="Business Plan"
                description="Create a professional business plan through AI"
                daysAgo={4}
                onClick={onOpen} // Open the modal on click
              /> */}
              <DesignCard
                icon={growthMoney} // Replace with appropriate icon
                title="Create a Sales Script"
                description="Craft a persuasive dialogue to guide customer conversations effectively."
                daysAgo={3}
              />
            </SimpleGrid>
          </Box>

          <Box
            position="relative"
            padding="10"
            marginTop={"30px"}
            zIndex={1400}
            justifyContent={{ base: "flex-start", sm: "center" }}
          >
            <Divider
              width={"100%"}
              display={{ base: "none", sm: "block" }}
              border={"1px solid #FCDCBB"}
              color={"#FCDCBB"}
            />
            <Link href="/business-plan">
              <AbsoluteCenter
                bg={{ base: "transparent", sm: "white" }}
                px="4"
                fontSize={{ base: "20px", sm: "20px" }}
                left={{ base: "20%", sm: "50%" }}
                alignItems={"left"}
              >
                Your AI Team
              </AbsoluteCenter>
            </Link>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            maxW={"100%"}
            height={"100%"}
            paddingX={"20px"}
            marginX={"auto"}
            px={{ base: "20px", sm: "0px" }}
            maxWidth={{ base: "100%", lg: "82rem" }}
          >
            {sections.map((section, idx) => (
              <Box key={idx} mb={8}>
                <Heading as="h2" size="md" mb={4}>
                  {section.title}
                </Heading>

                <Grid
                  templateColumns={{
                    base: "1fr",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                  gap={4}
                  className="grid-container-ai-team"
                >
                  {section.items.map((item, idx) => {
                    if (item.name === "Sales") {
                      return (
                        <GridItem key={idx} style={{ width: "100%" }}>
                          <Link
                            href={item.to}
                            cursor={"poi4"}
                            style={{
                              fontStyle: "normal",
                              textDecoration: "none",
                            }}
                          >
                            <Box
                              className="grid-item"
                              bg="#FEF8F5"
                              p={4}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"space-between"}
                              borderRadius="md"
                              // boxShadow="md"
                              width={"calc(100%-16px)"}
                              height={"100%"}
                              mb={4}
                            >
                              <Flex>
                                <Image
                                  src={item.image}
                                  w={"100px"}
                                  h={"100px"}
                                  mr={"18px"}
                                />
                                <Flex direction={"column"} mt={2}>
                                  <Heading
                                    as="h3"
                                    fontSize={"20px"}
                                    mb={2}
                                    fontWeight={500}
                                    color={"#333333"}
                                  >
                                    {item.name}
                                  </Heading>
                                  <Text
                                    mb={4}
                                    fontSize={"12px"}
                                    lineHeight={"21px"}
                                  >
                                    {item.description}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Flex
                                align="center"
                                borderTop={"1px solid #FCDCBB"}
                                pt={2}
                              >
                                <Text ml={4} fontSize={"12px"} fontWeight={400}>
                                  <span
                                    style={{
                                      color: "#F2682B",
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.conversations}
                                  </span>{" "}
                                  Conversations
                                </Text>
                              </Flex>
                            </Box>
                          </Link>
                        </GridItem>
                      );
                    }
                    return (
                      <Box
                        key={idx}
                        bg="#FEF8F5"
                        px={2}
                        py={4}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        borderRadius="md"
                        // boxShadow="md"
                        width={"calc(100%-16px)"}
                        height={"100%"}
                        mb={4}
                      >
                        <Link
                          href={item.to}
                          style={{
                            fontStyle: "normal",
                            textDecoration: "none",
                          }}
                        >
                          <Flex>
                            <Image
                              src={item.image}
                              w={"100px"}
                              h={"100px"}
                              mr={"18px"}
                              alt=""
                            />
                            <Flex direction={"column"} mt={2}>
                              <Heading
                                as="h3"
                                fontSize={"20px"}
                                mb={2}
                                fontWeight={500}
                                color={"#333333"}
                              >
                                {item.name}
                              </Heading>
                              <Text
                                mb={4}
                                fontSize={"12px"}
                                lineHeight={"21px"}
                              >
                                {item.description}
                              </Text>
                            </Flex>
                          </Flex>
                        </Link>
                        <Flex
                          align="center"
                          borderTop={"1px solid #FCDCBB"}
                          pt={2}
                        >
                    
                          <Text ml={4} fontWeight={400} fontSize={"12px"}>
                            <span
                              style={{
                                color: "#F2682B",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              {item.conversations}
                            </span>{" "}
                            Conversations
                          </Text>
                        </Flex>
                      </Box>
                    );
                  })}
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>
      </Flex>
      {/* Modal for Business Plan Creation */}
      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} gap={"8px"}>
          <Image
          src={customerRetention}
          boxSize={12}
          width={"40px"}
          height={"40px"}
        />
        <Flex display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} gap={"8px"}>
          <Text fontWeight={500}>            
          Create New Business Plan
          </Text>
    
        </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter display={"flex"} flexDirection={"row-reverse"} justifyContent={"space-between"}>
            <Button backgroundColor={"#e53e3e"} color={"#fff"} mr={3} onClick={handleBusinessPlanSubmit} _hover={{backgroundColor:"none",color:"none",scale:"10px"}} isLoading={isSubmitting}>
              Create
            </Button>
            <Button variant="ghost" backgroundColor={"#FBF8FC"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Flex>
      

      <Footer/>
      </>
  );
};

export default HomePage;
